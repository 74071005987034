import React from "react";
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Font } from "@react-pdf/renderer";

// ✅ Register Tiro Bangla from Local Public Folder
Font.register({
  family: "Hind Siliguri",
  src: "https://fonts.gstatic.com/s/hindsiliguri/v2/f2eEi2pbIa8eBfNwpUl0Am_MnNA9OgK8I1F23mNWOpE.ttf", // Correct path to the public folder
});

// ✅ Styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
    backgroundColor: "#fff",
    fontFamily: "Hind Siliguri", // ✅ Apply Tiro Bangla font
  },
  title: {
    fontSize: 20,
    textAlign: "center",
    marginBottom: 10,
    fontWeight: "bold",
  },
  text: {
    fontSize: 14,
    marginBottom: 5,
  },
});

// 📜 PDF Document Component
const BanglaPDFDocument = () => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View>
        <Text style={styles.title}> বাংলা বইয়ের তালিকা</Text>
        <Text style={styles.text}>১. সোনার তরী - রবীন্দ্রনাথ ঠাকুর</Text>
        <Text style={styles.text}>২. পথের পাঁচালী - বিভূতিভূষণ বন্দ্যোপাধ্যায়</Text>
        <Text style={styles.text}>৩. শ্রীকান্ত - শরৎচন্দ্র চট্টোপাধ্যায়</Text>
        <Text style={styles.text}>৪. দেবদাস - শরৎচন্দ্র চট্টোপাধ্যায়</Text>
        <Text style={styles.text}>৫. গোরা - রবীন্দ্রনাথ ঠাকুর</Text>
      </View>
    </Page>
  </Document>
);

// ✅ Main Component with Download Button
const BanglaPDFGenerator = () => {
  return (
    <div>
      <h2>📄 বাংলা পিডিএফ ডাউনলোড করুন</h2>
      <PDFDownloadLink document={<BanglaPDFDocument />} fileName="BanglaBookList.pdf">
        {({ loading }) => (
          <button style={{ padding: "10px 15px", background: "#007BFF", color: "#fff", border: "none", cursor: "pointer" }}>
            {loading ? "Creating PDF..." : "📥 ডাউনলোড পিডিএফ"}
          </button>
        )}
      </PDFDownloadLink>
    </div>
  );
};

export default BanglaPDFGenerator;
