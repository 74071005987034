import React, { useContext, useEffect, useState } from 'react'
import MyFooter from '../Components/MyFooter'
import login_page_ayat from '../assets/login_page_ayat.png';
import login_page_ayat_meaning from '../assets/login_page_ayat_meaning.png'
import './home.css';
import logo from '../assets/personal-report-logo.png'
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import Swal from 'sweetalert2'
import axios from 'axios';
//-------show password-----------
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye'
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import config from '../config';
import GoLoginButton from '../Components/GoogleLoginBut';
import DailyReport from './DailyReport';
import LoginButton from '../Components/LoginButton';
const Home = () => {
  const API_URL = config.authUrl;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [profileData, setProfileData] = useState('');
  const { loginUser, authTokens,user} = useContext(AuthContext);
  const token = authTokens?.access;
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  //----------show pass-----------
  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(eyeOff);


  const handleToggle = () => {
    if (type === 'password') {
      setIcon(eye);
      setType('text')
    } else {
      setIcon(eyeOff)
      setType('password')
    }
  }

  useEffect(() => {
    if (user) {
      fetch(`${config.apiUrl}userInfo/${user?.user_id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to fetch user data.');
          }
          return response.json();
        })
        .then((data) => {
          setProfileData(data);
        })
        .catch((error) => console.error('Error fetching user data:', error));
    }
  }, [user]);
  
  useEffect(() => {
    if (user && profileData && !profileData.is_active) {
      navigate('/account');
    }
  }, [user, profileData]);
  

  //------------------Profile-----------------

  // const handleLoginSuccess = async (response) => {
  //   console.log("response: " + JSON.stringify(response));
  //   try {
  //     const { credential } = response;
  //     console.log("credential" + credential);
  //     const backendResponse = await axios.post(`${API_URL} /dj-rest-auth/google/`, {
  //       access_token: credential,
  //       provider: 'google',
  //     });
  //     console.log("response: " + JSON.stringify(backendResponse));

  //     const { key } = backendResponse.data;
  //     // Save the token to localStorage or any state management library
  //     localStorage.setItem('authToken', key);
  //     alert('Login successful!');
  //   } catch (error) {
  //     console.error('Login failed:', error);
  //     alert('Login failed!');
  //   }
  // };
  // signup with google function END

  // const handleImageClick = () =>{
  //     inputRef.current.click();
  // }
  useEffect(() => {
    // window.location.reload();
    window.scrollTo(0, 0)
  }, [])


  
  const routeChange = () => {
    let path = `../signup`;
    navigate(path);
  }
  const isEmail = (input) => {
    // Simple regex check for email
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,})$/i;
    return re.test(String(input).toLowerCase());
  };

  const getUsernameByEmail = async (email) => {
    email =email.trim();
    try {
      const response = await axios.get(`${API_URL}get-username/`, { params: { email } });
      return response?.data?.username;
    } catch (error) {
      console.error("error fetching username ", error);
      Swal.fire({
        title: "Warning!",
        text: "User with this email does not exist",
        icon: "warning"
      });
      throw error;
    }
  };
  const handleLogin = async (e) => {
    e.preventDefault();



    if (username && password) {
      let mainUsername = username;
      if (isEmail(username)) {
        // console.log("this is mail  ");
        mainUsername = await getUsernameByEmail(username.trim());
        setUsername(mainUsername);
        // console.log("tar main username is ", mainUsername);
      }
      if (password.length < 8) {
        Swal.fire({
          title: "Warning!",
          text: "Password must be at least 8 characters.",
          icon: "warning"
        });
        return;
      }

      // Check if the username is a valid phone number
      const phoneNumber = parsePhoneNumberFromString(username, 'BD'); // Replace 'US' with your default country code
      let formattedUsername = mainUsername;

      if (phoneNumber && phoneNumber.isValid()) {
        formattedUsername = phoneNumber.number; // E.164 format
      } else if (!username.includes('@')) {
        Swal.fire({
          title: "Warning!",
          text: "Invalid phone number. Please enter a valid phone number.",
          icon: "warning"
        });
        return;
      }
      setLoading(true);
      try {
        const data = await loginUser(mainUsername, password);
        setLoading(false);
        if (data) {
          console.log("profile data",profileData)
          
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          title: "Warning!",
          text: "Login Failed. Invalid email or password.",
          icon: "warning"
        });
      }
    } else {
      Swal.fire({
        title: "Warning",
        text: "Please fill up all required fields.",
        icon: "warning"
      });
    }
  };
  return (
    user && profileData?.is_active ?(<DailyReport />) : (
      <div>
        <div className='home-middle'>
          <div className='login-not-user-top-part'>
            <img src={logo} alt='login-logo' />
            <div className='login-not-user-top-part-title'>Daily Report</div>

          </div>
          <div className='home-middle-quranic-verse'>
            <img src={login_page_ayat} className='login_page_ayat' alt='ayat' />
            <img src={login_page_ayat_meaning} className='login_page_ayat_meaning' alt='ayat-meaning' />
          </div>

          <div className='login-form'>
            <div className='login-form-left-part'>
              <form onSubmit={handleLogin}>
                {/* <label>UserName/Email: */}
                <input
                  placeholder='Phone/Email'
                  type="text"
                  name="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value.toLocaleLowerCase().trim())}
                  className='login-form-left-part-form-input'
                />
                {/* </label> */}
                {/* <label>Password: */}
                <div className='home-show-password'>
                  <input
                    placeholder='Password'
                    type={type}
                    name="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className='login-form-left-part-form-input'
                    autoComplete="current-password"
                  />
                  <span className="flex justify-around items-center" onClick={handleToggle}>
                    <Icon className="absolute mr-10" icon={icon} size={15} />
                  </span>
                </div>
                <div className='login-form-left-part-under-form'>
                  {/* <span1><Checkbox/>Remember Password</span1> */}
                  <span> <Link to={`./forget_pass`} className='login-form-left-part-under-form-forgot'> Forgot Password?</Link> </span>
                </div>
                {/* </label> */}
                <Button className='signup-form-left-part-button' type="submit">{loading ? 'Loading...' : 'LOGIN'}</Button>

                <div style={{ alignSelf: 'center' }}>OR</div>
                {/* Login with google account code start here */}

                {/* <GoogleOAuthProvider clientId="985147780700-q4a328ut5bh09ftl1dpluu7vd29erlkb.apps.googleusercontent.com">
                  <GoogleLogin
                    onSuccess={handleLoginSuccess}
                    onError={() => { 
                      console.log('Login Failed');
                    }}
                    useOneTap
                  />
                </GoogleOAuthProvider> */}
                {/* <GoogleLogin className='home-sign-in-with-google' /> */}
                {/* <LoginButton /> */}
              </form>

              <div className='login-form-left-part-bottom-part'>
                New User? <span onClick={routeChange}>Create an Account</span>
              </div>
            </div>
            <div className='login-form-right-part'>
              <img src={logo} alt='login-logo' />
            </div>
          </div>
        </div>
        <MyFooter />
      </div>
    )
  )
}

export default Home