import React, { useEffect } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import Cookies from 'js-cookie';

const clientId = '736594952670-61rjgbndkd1slvorenhbg9b90prbvjl5.apps.googleusercontent.com';  // Replace with your Google Client ID

function App() {
  const csrfToken = Cookies.get('csrftoken');
  useEffect(() => {
    
// console.log("the token is ",csrfToken);
  
   
  }, [])
  
  const handleLoginSuccess = (response) => {


  const csrfToken = Cookies.get('csrftoken');
    const { credential } = response;


    // Send the credential to the Django backend
    fetch('http://localhost:8000/accounts/google/login/', {
      method: 'POST',
      
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrfToken,
      },
      withCredentials: true,
      body: JSON.stringify({
        access_token: credential,  // Send the access token to the backend
        provider: 'google'  // Specify the provider
      }),
    }
    
  )
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
        // Handle login success and set user data or tokens
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  console.log("the token is ",csrfToken);

  const handleLoginError = (response) => {
    console.error('Login failed:', response);
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div className="App">
        {/* <h1>Login with Google</h1> */}
        <GoogleLogin
          onSuccess={handleLoginSuccess}
          onError={handleLoginError}
        />
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
