import Navbar from '../Components/Navbar'
import MyFooter from '../Components/MyFooter'
import './daily-report.css'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {Container,Row,Col, Button, ButtonGroup} from 'react-bootstrap';
import BorderedSection from './BorderSection';
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Checkbox } from 'flowbite-react';
import { API } from '../api-service';
import Swal from 'sweetalert2';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const DailyReport = () => {
  const { user,authTokens } = useContext(AuthContext);
  const token = authTokens?.access;
  // const token = 'token';
  // const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);

  const [quranStudy, setQuranStudy] = useState(null);
  const [quranMemorize, setQuranMemorize] = useState(null);
  const [quranDars, setQuranDars] = useState(null);
  const [hadithStudy, setHadithStudy] = useState(null);
  const [hadithMemorize, setHadithMemorize] = useState(null);
  const [hadithDars, setHadithDars] = useState(null);
  const [bookStudyIslamic, setBookStudyIslamic] = useState(null);
  const [bookStudyOther, setBookStudyOther] = useState(null);
  const [salatJamat, setSalatJamat] = useState(null);
  const [salatKaja, setSalatKaja] = useState(null);
  const [memberContact, setMemberContact] = useState(null);
  const [workerContact, setWorkerContact] = useState(null);
  const [distributionIslamic, setDistributionIslamic] = useState(null);
  const [distributionOther, setDistributionOther] = useState(null);
  const [meetDawahTarget, setMeetDawahTarget] = useState(null);
  const [meetWorker, setMeetWorker] = useState(null);
  const [meetMember, setMeetMember] = useState(null);
  const [timeSpend, setTimeSpend] = useState(null);
  const [selfCriticism, setSelfCriticism] = useState(false);
  const [familyMeeting, setFamilyMeeting] = useState(false);
  const [socialWork, setSocialWork] = useState(null);
  const [travel, setTravel] = useState(null);
  const [debt, setDebt] = useState(false);
  const [report, setReport] = useState(false);
  const [comment, setComment] = useState('');
  const [reports, setReports] = useState([]);

  const [gotreport, setGotreport] = useState(false);
  const [allreports, setAllreports] = useState([]);
  const [reportid, setReportid] = useState(0);

  const minimumDate=new Date('2022-01-01');
  const { t,i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!user) {
      Swal.fire({
        title: 'Warning',
        text: "You are not logged in. Please log in to access your profile.",
        icon: 'warning',
        confirmButtonText: 'Ok.'
      }).then((result) => {
        if (result['isConfirmed']){
          // Put your function here
          navigate('/');
        }
      })
      // alert('You are not logged in. Please log in to access your profile.');
      // navigate('/');
    }
  }, [user]);

  useEffect(() => {
    API.getReports(token,user?.user_id)
    .then( resp =>  setAllreports(resp))
    .catch (error => console.log(error))
  }, [gotreport]);

  useEffect(() => {
      getReport();
  }, [allreports, selectedDate]);

  // const handleDateChange = (event) => {
  //   setSelectedDate(event.target.value);
  // };

  const formateDate = () => {
    const year = selectedDate.getFullYear();
    const month = ('0' + (selectedDate.getMonth() + 1)).slice(-2); // Months are zero indexed, so we add 1
    const day = ('0' + selectedDate.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const prevDay = () => {
    const prev = moment(selectedDate).subtract(1, 'days');
    if(prev<minimumDate){
        Swal.fire({
          title: "Warning!",
          text: "You cant go beyond that!.",
          icon: "warning"
        });
    }
    else{
      setSelectedDate(new Date(prev));
    }
  }

  const nextDay = () => {
    const nxt = moment(selectedDate).add(1, 'days');
    if(nxt <= moment()) {
      setSelectedDate(new Date(nxt));
    }
    else{
        Swal.fire({
          title: "Warning!",
          text: "You can't keep future report.",
          icon: "warning"
        });
    }
  }

  const getReport = () => {
    //setGotVal(false);
    if(allreports.length>0) {
        const report = allreports ? allreports.filter((item) => item.date === formateDate(selectedDate) ) : null;
        //setP(report.length);
        //console.log(report[0].id)
        if(report.length !== 0) {
            setReportid(report[0].id);
            // console.log('show'+report[0].id);
            //console.log(report[0].id)
            //setQuranStudy(report.map((item) => item.quranStudy));
            setQuranStudy(report[0].quranStudy == null ? null : report[0].quranStudy);
            setQuranMemorize(report[0].quranMemorize == null ? null : report[0].quranMemorize);
            setQuranDars(report[0].quranDars == null ? null : report[0].quranDars);
            setHadithStudy(report[0].hadithStudy == null ? null : report[0].hadithStudy);
            setHadithMemorize(report[0].hadithMemorize == null ? null : report[0].hadithMemorize);
            setHadithDars(report[0].hadithDars == null ? null : report[0].hadithDars);

            setBookStudyIslamic(report[0].bookStudyIslamic == null ? null : report[0].bookStudyIslamic);
            setBookStudyOther(report[0].bookStudyOther == null ? null : report[0].bookStudyOther);
            setSalatJamat(report[0].salatJamat == null ? null : report[0].salatJamat);
            setSalatKaja(report[0].salatKaja == null ? null : report[0].salatKaja);

            setMemberContact(report[0].memberContact == null ? null : report[0].memberContact);
            setWorkerContact(report[0].workerContact == null ? null : report[0].workerContact);
            setDistributionIslamic(report[0].distributionIslamic == null ? null : report[0].distributionIslamic);
            setDistributionOther(report[0].distributionOther == null ? null : report[0].distributionOther);
            
            setMeetDawahTarget(report[0].meetDawahTarget == null ? null : report[0].meetDawahTarget);
            setMeetWorker(report[0].meetWorker == null ? null : report[0].meetWorker);
            setMeetMember(report[0].meetMember == null ? null : report[0].meetMember);
            setTimeSpend(report[0].timeSpend == null ? null : report[0].timeSpend);
            
            setSelfCriticism(report[0].selfCriticism);
            setFamilyMeeting(report[0].familyMeeting);
            setDebt(report[0].debt);
            setReport(report[0].report);
            
            setSocialWork(report[0].socialWork == null ? null : report[0].socialWork);
            setTravel(report[0].travel == null ? null : report[0].travel);
            setComment(report[0].comment == null ? '' : report[0].comment);
        }
        else {
            setReportid(0);
            setQuranStudy(null);
            setQuranMemorize(null);
            setQuranDars(null);
            setHadithStudy(null);
            setHadithMemorize(null);
            setHadithDars(null);

            setBookStudyIslamic(null);
            setBookStudyOther(null);
            setSalatJamat(null);
            setSalatKaja(null);

            setMemberContact(null);
            setWorkerContact(null);
            setDistributionIslamic(null);
            setDistributionOther(null);
            
            setMeetDawahTarget(null);
            setMeetWorker(null);
            setMeetMember(null);
            setTimeSpend(null);
            
            setSelfCriticism(false);
            setFamilyMeeting(false);
            setDebt(false);
            setReport(false);
            
            setSocialWork(null);
            setTravel(null);
            setComment(null);
        }
    }
    //setGotVal(true);
  }

  

  const handleSubmit = async () => {
    if (!user) {
      Swal.fire({
        title: 'Warning',
        text: "Login First. You have to login.",
        icon: 'warning',
        confirmButtonText: 'Ok.'
      }).then((result) => {
        if (result['isConfirmed']){
          // Put your function here
          navigate('/login');
        }
      })
      return;      
    } 
    else{ 
      setLoading(true);
      const data = {
        user: user.user_id,
        date: formateDate(selectedDate),
        quranStudy,
        quranMemorize,
        quranDars,
        hadithStudy,
        hadithMemorize,
        hadithDars,
        bookStudyIslamic,
        bookStudyOther,
        salatJamat,
        salatKaja,
        memberContact,
        workerContact,
        distributionIslamic,
        distributionOther,
        meetDawahTarget,
        meetWorker,
        meetMember,
        timeSpend,
        selfCriticism,
        familyMeeting,
        socialWork,
        travel,
        debt,
        report,
        comment,
      }; 
      if(reportid==0){
        //setLoading(true);
        try {
          //const apiUrl = 'https://munamatrimonial.com/reportapp/api/input/';
          //const apiUrl = 'http://127.0.0.1:8000/api/input/';
          console.log('Data being sent:', data);
          //const response = await axios.post(apiUrl, data);
          //console.log(response);
          API.createReport(data, token)
              .then( resp => {
                setLoading(false);
                  // console.log(resp);
                  if(resp.user == user.user_id) {
                    Swal.fire({
                      title: 'Success',
                      text: "Report successfully Added",
                      icon: 'success',
                      confirmButtonText: 'Ok.'
                    })  
                    setGotreport(!gotreport);
                  }
                  else {
                    Swal.fire({
                      title: 'Warning',
                      text: "Error resp. Report Not Added",
                      icon: 'warning',
                      confirmButtonText: 'Ok.'
                    })  
                  }
              })
              .catch(error => {
                setLoading(false);
                  //console.log(error);
                  alert(`Submission Error. An error occurred: ${error.message}. Please try again later.`);
              });
          
          //alert('Report Submitted. Your daily report has been submitted successfully.');
          //navigate('/account');
        } catch (error) {
          setLoading(false);
          console.error('API Error:', error);
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Error data:', error.response.data);
            console.error('Error status:', error.response.status);
            console.error('Error headers:', error.response.headers);
            alert(`Submission Error. Server responded with status ${error.response.status}: ${error.response.data.detail || error.response.data.message || 'Unknown error'}`);
          } else if (error.request) {
            // The request was made but no response was received
            console.error('Error request:', error.request);
            alert('Submission Error. No response received from the server. Please check your internet connection and try again later.');
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error message:', error.message);
            alert(`Submission Error. An error occurred: ${error.message}. Please try again later.`);
          }
        }
      }
      else{
        API.updateReport(reportid, data, token)
            .then( resp => {
              setLoading(false);
                //console.log(resp);
                //console.log(data);
                //console.log(reportid);
                if(resp.user == user.user_id) {
                  Swal.fire({
                    title: 'Success',
                    text: "Report successfully Updated!",
                    icon: 'success',
                    confirmButtonText: 'Ok.'
                  })
                    setGotreport(!gotreport);
                }
                else {
                  Swal.fire({
                    title: 'Warning',
                    text: "Oops! Report Not Updated!",
                    icon: 'warning',
                    confirmButtonText: 'Ok.'
                  })
                }
            })
            .catch(error => {
              setLoading(false);
                //console.log(error);
                Swal.fire({
                  title: 'Warning',
                  text: "Error! Report Not Updated!",
                  icon: 'warning',
                  confirmButtonText: 'Ok.'
                })
            });
        }
    }
  };

  if (!user) {
    return (
      <div >
        <div >
          <p >You are not logged in</p>
          <p >Please Login to proceed</p>
          <button onClick={() => navigate('/')}>Login</button>
        </div>
      </div>
    );
  }

  // Utility function to handle both conversions
  // Utility function to handle both conversions, with null/undefined check
  const convertNumbers = (value, toBengali = true) => {
    if (!value) return '';  // Ensure value is not null or undefined

    const englishToBengaliDigits = {
      '0': '০', '1': '১', '2': '২', '3': '৩', '4': '৪', '5': '৫',
      '6': '৬', '7': '৭', '8': '৮', '9': '৯',
    };

    const bengaliToEnglishDigits = {
      '০': '0', '১': '1', '২': '2', '৩': '3', '৪': '4',
      '৫': '5', '৬': '6', '৭': '7', '৮': '8', '৯': '9',
    };

    const digitsMap = toBengali ? englishToBengaliDigits : bengaliToEnglishDigits;
    
    // Ensure that `value` is a string before calling `split`
    return value.toString().split('').map(digit => digitsMap[digit] || digit).join('');
  };


  const isBengali = i18n.language === 'bn'; // Check if the current language is Bengali

  const handleInputChange = (e, setter) => {
    let value = e.target.value;

    // Convert Bengali digits to English internally, if the language is Bengali
    if (isBengali) {
      value = convertNumbers(value, false); // Convert Bengali to English for internal use
    }

    // Allow only numeric input (in either Bengali or English)
    if (/^\d*$/.test(value)) {
      setter(value); // Update state
    }
    if(setter === setTimeSpend){
      if (/^\d*\.?\d*$/.test(value)) {
        setTimeSpend(value);  // Keep it as a string to allow user to input fractional part
      }
    }

  };



  

  return ( 
    <div>
        <Navbar/>
        <div className='daily-report-middle-part'>
          <div className='daily-report-middle-part-1st-row'>
            <div className='daily-report-middle-part-1st-row-title'>{t('personal-report')}</div>


            <div className='daily-report-middle-part-1st-row-date'>
              <Button onClick={prevDay}>&lt;</Button>
              {/* <input type="date" value={selectedDate} onChange={handleDateChange} /> */}
              <DatePicker 
              selected={selectedDate} 
              dateFormat="dd-MM-yyyy"
              onChange={(date) => setSelectedDate(date)} 
              maxDate={new Date()}
              className='daily-report-middle-part-1st-row-datepicker'
            />
              <Button onClick={nextDay}>&gt;</Button>
            </div>

          </div>
          

          

          <Container className='daily-report-middle-part-2nd-row'>
            <Row className='daily-report-middle-part-2nd-row-row'>
              <Col>{t('quran')} </Col><hr/>
            </Row>
            <Row className='daily-report-middle-part-2nd-row-row'>
              <Col>{t('reading')}</Col>
              <Col>
              <input
                className='daily-report-middle-part-2nd-row-row-input'
                type="text"
                value={isBengali ? convertNumbers(quranStudy, true) : quranStudy} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setQuranStudy)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
              </Col>
              <Col>{t('memorization')}</Col>
              <Col>
              <input
                className='daily-report-middle-part-2nd-row-row-input'
                type="text"
                value={isBengali ? convertNumbers(quranMemorize, true) : quranMemorize} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setQuranMemorize)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
              </Col>
              <Col>{t('dars')}</Col>
              <Col>
              <input
                className='daily-report-middle-part-2nd-row-row-input'
                type="text"
                value={isBengali ? convertNumbers(quranDars, true) : quranDars} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setQuranDars)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
              </Col>
            </Row>
            <Row className='daily-report-middle-part-2nd-row-row'>
                <Col>{t('hadith')}</Col><hr/>
            </Row>
            <Row className='daily-report-middle-part-2nd-row-row'>
            <Col>{t('reading')}</Col>
              <Col>
              <input
                className='daily-report-middle-part-2nd-row-row-input'
                type="text"
                value={isBengali ? convertNumbers(hadithStudy, true) : hadithStudy} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setHadithStudy)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
              </Col>
              <Col>{t('memorization')}</Col>
              <Col>
              <input
                className='daily-report-middle-part-2nd-row-row-input'
                type="text"
                value={isBengali ? convertNumbers(hadithMemorize, true) : hadithMemorize} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setHadithMemorize)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
              </Col>
              <Col>{t('dars')}</Col>
              <Col>
              <input
                className='daily-report-middle-part-2nd-row-row-input'
                type="text"
                value={isBengali ? convertNumbers(hadithDars, true) : hadithDars} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setHadithDars)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
              </Col>
            </Row>

            <Row className='daily-report-middle-part-2nd-row-row'>
                <Col>{t('literature')}</Col><hr/>
            </Row>

            <Row className='daily-report-middle-part-2nd-row-row'>
              <Col>{t('islamic')}</Col>
              <Col>
              <input
                className='daily-report-middle-part-2nd-row-row-input'
                type="text"
                value={isBengali ? convertNumbers(bookStudyIslamic, true) : bookStudyIslamic} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setBookStudyIslamic)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
              </Col>
              <Col>{t('other')}</Col>
              <Col>
              <input
                className='daily-report-middle-part-2nd-row-row-input'
                type="text"
                value={isBengali ? convertNumbers(bookStudyOther, true) : bookStudyOther} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setBookStudyOther)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
              </Col>
              <Col xs></Col>
              <Col xs></Col>
            </Row>

            <Row className='daily-report-middle-part-2nd-row-row'>
                <Col>{t('namaz')}</Col><hr/>
            </Row>

            <Row className='daily-report-middle-part-2nd-row-row'>
              <Col>{t('jamat')}</Col>
              <Col>
              <input
                className='daily-report-middle-part-2nd-row-row-input'
                type="text"
                value={isBengali ? convertNumbers(salatJamat, true) : salatJamat} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setSalatJamat)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
              </Col>
              <Col>{t('kadha')}</Col>
              <Col>
              <input
                className='daily-report-middle-part-2nd-row-row-input'
                type="text"
                value={isBengali ? convertNumbers(salatKaja, true) : salatKaja} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setSalatKaja)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
              </Col>
              <Col ></Col>
              <Col ></Col>
            </Row>

            <Row className='daily-report-middle-part-2nd-row-row'>
                <Col>{t('contact')}</Col><hr/>
            </Row>

            <Row className='daily-report-middle-part-2nd-row-row'>
              <Col>{t('worker')}</Col>
              <Col>
              <input
                className='daily-report-middle-part-2nd-row-row-input'
                type="text"
                value={isBengali ? convertNumbers(workerContact, true) : workerContact} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setWorkerContact)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
              </Col>
              <Col>{t('ruqon')}</Col>
              <Col>
              <input
                className='daily-report-middle-part-2nd-row-row-input'
                type="text"
                value={isBengali ? convertNumbers(memberContact, true) : memberContact} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setMemberContact)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
              </Col>
              <Col ></Col>
              <Col ></Col>
            </Row>

            <Row className='daily-report-middle-part-2nd-row-row'>
                <Col>{t('distribution')}</Col><hr/>
            </Row>

            <Row className='daily-report-middle-part-2nd-row-row'>
              <Col>{t('islamic')}</Col>
              <Col>
              <input
                className='daily-report-middle-part-2nd-row-row-input'
                type="text"
                value={isBengali ? convertNumbers(distributionIslamic, true) : distributionIslamic} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setDistributionIslamic)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
              </Col>
              <Col>{t('other')}</Col>
              <Col>
              <input
                className='daily-report-middle-part-2nd-row-row-input'
                type="text"
                value={isBengali ? convertNumbers(distributionOther, true) : distributionOther} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setDistributionOther)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
              </Col>
              <Col ></Col>
              <Col ></Col>
            </Row>

            <Row className='daily-report-middle-part-2nd-row-row'>
                <Col>{t('meet')}</Col><hr/>
            </Row>
              <Row className='daily-report-middle-part-2nd-row-row'>
                <Col>{t('dawah-target')}</Col>
                <Col>
                <input
                className='daily-report-middle-part-2nd-row-row-input'
                type="text"
                value={isBengali ? convertNumbers(meetDawahTarget, true) : meetDawahTarget} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setMeetDawahTarget)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
                </Col>
                <Col>{t('worker')}</Col>
                <Col>
                <input
                className='daily-report-middle-part-2nd-row-row-input'
                type="text"
                value={isBengali ? convertNumbers(meetWorker, true) : meetWorker} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setMeetWorker)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
                </Col>
                <Col>{t('ruqon')}</Col>
                <Col>
                <input
                className='daily-report-middle-part-2nd-row-row-input'
                type="text"
                value={isBengali ? convertNumbers(meetMember, true) : meetMember} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setMeetMember)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
                </Col>
              </Row>
            <Row className='daily-report-middle-part-2nd-row-row'>
            </Row>
            <Row className='daily-report-middle-part-2nd-row-row'>
                <Col >{t('org-time')}</Col>
                <Col >
                <input 
                  className='daily-report-middle-part-2nd-row-row-input' 
                  type='text'
                  value={isBengali ? convertNumbers(timeSpend, true) : timeSpend} // Display in Bengali or English 
                  onChange={(e) => handleInputChange(e, setTimeSpend)}
                  onBlur={() => {
                    // Convert to a float when the user leaves the input field
                    if (timeSpend !== '') {
                      setTimeSpend(parseFloat(timeSpend)); // Convert to number only when necessary
                    }
                  }}
                />
                </Col>
                
                <Col></Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>
            </Row>

            <Row className='daily-report-middle-part-2nd-row-row'>
                <Col>{t('misscellaneous')}</Col><hr/>
            </Row>

            <Row className='daily-report-middle-part-2nd-row-row'>
                <Col>{t('criticism')}</Col>
                <Col>
                <Checkbox 
                checked={selfCriticism}
                //value={selfCriticism}
                onChange={(e) => setSelfCriticism(!selfCriticism)}
                />
                </Col>
                <Col>{t('family')}</Col>
                <Col>
                <Checkbox
                checked={familyMeeting}
                //value={familyMeeting}
                onChange={(e) => setFamilyMeeting(!familyMeeting)}
                />
                
                </Col>
                <Col>{t('social')}</Col>
                <Col>
                <input
                  className='daily-report-middle-part-2nd-row-row-input'
                  type="text"
                  value={isBengali ? convertNumbers(socialWork, true) : socialWork} // Display in Bengali or English
                  onChange={(e) => handleInputChange(e, setSocialWork)}
                  // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
                />
                </Col>
                
            </Row>

            <Row className='daily-report-middle-part-2nd-row-row'>
                <Col>{t('debt')}</Col>
                <Col>
                <Checkbox
                checked={debt}
                //value={debt}
                onChange={(e) => setDebt(!debt)}
                />
                
                </Col>
                <Col>{t('report')}</Col>
                <Col>
                <Checkbox
                checked={report}
                //value={report}
                onChange={(e) => setReport(!report)}
                />
                </Col>

                <Col>{t('visit')}</Col>
                <Col>
                <input
                  className='daily-report-middle-part-2nd-row-row-input'
                  type="text"
                  value={isBengali ? convertNumbers(travel, true) : travel} // Display in Bengali or English
                  onChange={(e) => handleInputChange(e, setTravel)}
                  // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
                />
                </Col>
                
                
            </Row>

            <Row>
                <Col className='daily-report-middle-part-2nd-row-row-comment'>{t('comment')}</Col>
                <textarea className='daily-report-middle-part-2nd-row-row-textarea' type='text'
                  value={comment !== null ? comment : ''} 
                  onChange={(e) => setComment(e.target.value)}
                />
                <Col></Col>
                <Col></Col>
                <Col></Col>
            </Row>
          </Container> 

          <div className='daily-report-save' onClick={handleSubmit}>{loading ? t('saving') : reportid === 0? t('save') : t('update')}</div>
          

        </div>
        <MyFooter/>
    </div>
  )
}

export default DailyReport