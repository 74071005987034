import React, { useContext, useEffect, useState } from 'react';
import './termscondtion.css'
import Navbar from '../Components/Navbar';
import MyFooter from '../Components/MyFooter';
import AuthContext from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { API } from '../api-service';

const TermsConditions = () => {
  const {  user } = useContext(AuthContext);
  const [allTac, setAllTac] = useState([])
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTAC = async () => {
      try {
        const response = await API.getTAC()
        .then((result) => {
          // console.log("all tac ", result)
          setAllTac(result);

        })
        
      } catch (error) {
        console.log("Failed to fetch the Question and Answer")
      }
      
    }

    fetchTAC();
  }, [])
  const gotoHomePage = () => {
    navigate('/');
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  
  return (
    <div>
   {user &&   <Navbar/>}
      <div className="terms-container">
      {/* Introduction Section */}
      <section className="terms-section">
      <Button onClick={gotoHomePage}>Got to Homepage</Button>
        <h1 className="terms-heading">Terms and Conditions</h1>
        <p className="terms-paragraph">These terms and conditions outline the rules and regulations for the use of our website.</p>
      </section>

      {/* Acceptance of Terms Section */}
      {
        allTac.map((tac,id)=>(
          <section className="terms-section text-justify" key={id}>
        <h2 className="terms-subheading">{tac?.term}</h2>
        <p className="terms-paragraph">{tac?.condition}</p>
      </section>

        ))
      }
      
    </div>
      <MyFooter/>
    </div>
    
  );
};

export default TermsConditions;
