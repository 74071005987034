import React, { useState, useRef, useContext } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import './contactus.css';
import Swal from 'sweetalert2';
import config from '../config';
import AuthContext from '../context/AuthContext';
import ReCAPTCHA from 'react-google-recaptcha';


function SendMail() {
    const form = useRef();
    const [loading, setLoading] = useState(false);
    const [contactData, setContactData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });
    const { authTokens } = useContext(AuthContext);
    const token = authTokens?.access;
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const recaptchaSiteKey = "6LedRFwqAAAAAHnwmTlh3NOP4oyUYMeEmTiVtdoW"; // Replace with your reCAPTCHA 

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setContactData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const sendEmail = async (e) => {
        e.preventDefault();
        if (!recaptchaToken) {
            Swal.fire({
                title: 'Warning!',
                text: 'Please complete the reCAPTCHA.',
                icon: 'warning',
            });
            return;
        }
        setLoading(true);
        try {
            const response = await fetch(`${config.apiUrl}contact_us/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    
                },
                body: JSON.stringify(contactData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            Swal.fire({
                title: "Jazakallahu Khairan",
                text: "We've received your email. We'll reply to your email soon, Insh'Allah.",
                icon: "success",
            });
            setLoading(false);

            // Clear the form
            setContactData({
                name: '',
                email: '',
                subject: '',
                message: '',
            });

        } catch (error) {
            console.error("Error:", error);
            Swal.fire({
                title: "Warning!",
                text: "Failed to send email. Please try again!",
                icon: "warning",
            });
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <section className="contact-form-section">
                <h2>Get in Touch</h2>
                <form className="contact-form p-4 rounded " ref={form} onSubmit={sendEmail}>
                    <div className="contact-form-group">
                        <label htmlFor="name">Name</label>
                        <input
                            onChange={handleInputChange}
                            type="text"
                            value={contactData.name}
                            id="name"
                            name="name"
                            placeholder="Your Name"
                            required
                        />
                    </div>
                    <div className="contact-form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            onChange={handleInputChange}
                            type="email"
                            value={contactData.email}
                            name="email"
                            id="email"
                            placeholder="Your Email"
                            required
                        />
                    </div>
                    <div className="contact-form-group">
                        <label htmlFor="subject">Subject</label>
                        <input
                            value={contactData.subject}
                            onChange={handleInputChange}
                            type="text"
                            name="subject"
                            id="subject"
                            placeholder="Subject"
                            required
                        />
                    </div>
                    <div className="contact-form-group">
                        <label htmlFor="message">Message</label>
                        <textarea
                            value={contactData.message}
                            onChange={handleInputChange}
                            id="message"
                            name="message"
                            rows="5"
                            placeholder="Your Message"
                            required
                        ></textarea>
                    </div>
                   
                                    {/* Google reCAptcha is here  */}
                        <ReCAPTCHA
                            sitekey={recaptchaSiteKey}
                            onChange={(token) => setRecaptchaToken(token)}
                        />
                   

                    <Button type="submit" disabled={loading} className='mt-2'>
                        {loading ? <Spinner animation="border" size="sm" role="status" aria-hidden="true" /> : "Send Message"}
                    </Button>
                </form>
            </section>
        </div>
    );
}

export default SendMail;
