import React, { useContext, useEffect, useState } from 'react'
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, BlobProvider, Font } from '@react-pdf/renderer';
import Navbar from '../Components/Navbar'
import MyFooter from '../Components/MyFooter'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './report-summary.css'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ShareIcon from '@mui/icons-material/Share';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import { Container, Row, Col } from 'react-grid-system';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { API } from '../api-service';
import Swal from 'sweetalert2';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
Font.register({
  family: "Hind Siliguri",
  src: "https://fonts.gstatic.com/s/hindsiliguri/v2/f2eEi2pbIa8eBfNwpUl0Am_MnNA9OgK8I1F23mNWOpE.ttf", // Correct path to the public folder
});

const ReportSummary = () => {
  const { t, i18n } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const { user, authTokens } = useContext(AuthContext);
  const token = authTokens?.access;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [quranStudy, setQuranStudy] = useState(null);
  const [quranStudyDays, setQuranStudyDays] = useState(null);
  const [quranStudyDaysPrev, setQuranStudyDaysPrev] = useState(null);
  const [quranMemorize, setQuranMemorize] = useState(null);
  const [quranDars, setQuranDars] = useState(null);
  const [hadithStudy, setHadithStudy] = useState(null);
  const [hadithStudyDays, setHadithStudyDays] = useState(null);
  const [hadithStudyDaysPrev, setHadithStudyDaysPrev] = useState(null);
  const [hadithMemorize, setHadithMemorize] = useState(null);
  const [hadithDars, setHadithDars] = useState(null);
  const [bookStudyIslamic, setBookStudyIslamic] = useState(null);
  const [bookStudyIslamicPrev, setBookStudyIslamicPrev] = useState(null);
  const [bookStudyOther, setBookStudyOther] = useState(null);
  const [salatJamat, setSalatJamat] = useState(null);
  const [salatJamatPrev, setSalatJamatPrev] = useState(null);
  const [salatKaja, setSalatKaja] = useState(null);
  const [memberContact, setMemberContact] = useState(null);
  const [memberContactPrev, setMemberContactPrev] = useState(null);
  const [workerContact, setWorkerContact] = useState(null);
  const [workerContactPrev, setWorkerContactPrev] = useState(null);
  const [distributionIslamic, setDistributionIslamic] = useState(null);
  const [distributionIslamicPrev, setDistributionIslamicPrev] = useState(null);
  const [distributionOther, setDistributionOther] = useState(null);
  const [meetDawahTarget, setMeetDawahTarget] = useState(null);
  const [meetDawahTargetPrev, setMeetDawahTargetPrev] = useState(null);
  const [meetWorker, setMeetWorker] = useState(null);
  const [meetWorkerPrev, setMeetWorkerPrev] = useState(null);
  const [meetMember, setMeetMember] = useState(null);
  const [timeSpend, setTimeSpend] = useState(null);
  const [timeSpendPrev, setTimeSpendPrev] = useState(null);
  const [selfCriticism, setSelfCriticism] = useState(null);
  const [selfCriticismPrev, setSelfCriticismPrev] = useState(null);
  const [familyMeeting, setFamilyMeeting] = useState(null);
  const [familyMeetingPrev, setFamilyMeetingPrev] = useState(null);
  const [socialWork, setSocialWork] = useState(null);
  const [socialWorkPrev, setSocialWorkPrev] = useState(null);
  const [travel, setTravel] = useState(null);
  const [travelPrev, setTravelPrev] = useState(null);
  const [debt, setDebt] = useState(null);
  const [debtPrev, setDebtPrev] = useState(null);
  const [report, setReport] = useState(null);
  const [reportPrev, setReportPrev] = useState(null);
  const [comment, setComment] = useState('');

  const [gotSummary, setGotSummary] = useState(false);
  const [allSummary, setAllSummary] = useState([]);
  const [summaryId, setSummaryId] = useState(0);
  const minimumDate = new Date('2022-01-01');
  const [pdfUrl, setPdfUrl] = React.useState(null);
  // Styles
  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontSize: 10,
      fontFamily: "Hind Siliguri",
    },
    title: {
      fontSize: 16,
      fontWeight: 'bold',
      marginBottom: 10,
      textAlign: 'center',
    },
    section: {
      marginBottom: 10,
    },
    table: {
      display: 'table',
      width: '100%',
      marginVertical: 10,
    },
    row: {
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderBottomColor: '#ddd',
      paddingBottom: 3,
      marginBottom: 3,
    },
    cell: {
      flex: 1,
      padding: 3,
    },
    bold: {
      fontWeight: 'bold',
    },

  });




  const MyPDFDocument = ({ reportData, selectedDate, comment }) => {
    const currentMonth = selectedDate.toLocaleString('default', { month: 'long' });
    const currentYear = selectedDate.getFullYear();
    // console.log('reportData:', reportData);
    //     {t('quran')}
    //  {t('days')}
    //  {t('reading')}
    // {t('memorization')}
    // {t('dars')}
    //  {t('hadith')}
    //  {t('days')}
    //  {t('reading')}
    //  {t('memorization')}
    //  {t('dars')}
    //  {t('literature')}
    //  {t('islamic')}
    //   {t('other')}
    //  {t('namaz')}
    //  {t('jamat')}
    //  {t('kadha')}
    // {t('contact')}
    //  {t('ruqon')}
    //   {t('distribution')}
    //  {t('islamic')}
    //    {t('other')}
    //  {t('meet')}
    //  {t('dawah-target')}
    //  {t('worker')}
    //  {t('ruqon')}
    //  {t('org-time')}
    //  {t('misscellaneous')}
    // {t('criticism')}
    // {t('social')}
    // >{t('debt')
    // {t('family')}<
    // {t('visit')}
    // {t('report')}
    // {t('comment')}
    // }`
    const reportContent = [
      { label: `${t('quran')}}:`, content: ` ${t('days')}- ${quranStudyDays} | ${t('reading')} -${quranStudy} | ${t('memorization')}-${quranMemorize} | ${t('dars')}-${quranDars}` },
      { label: `${t('hadith')}:`, content: ` ${t('days')}- ${hadithStudyDays} | ${t('reading')} -${hadithStudy} | ${t('memorization')}-${hadithMemorize} | ${t('dars')}-${hadithDars}` },
      { label: `${t('literature')}:`, content: `${t('islamic')}-${bookStudyIslamic} | ${t('other')}-${bookStudyOther}` },
      { label: `${t('namaz')}`, content: `${t('jamat')}-${salatJamat} | ${t('kadha')}-${salatKaja}` },
      { label: `${t('contact')}`, content: ` ${t('worker')}-${workerContact} | ${t('ruqon')}-${memberContact}` },
      { label: `${t('distribution')}`, content: `${t('islamic')}-${distributionIslamic} | ${t('other')}-${distributionOther}` },
      { label: `${t('meet')}`, content: `${t('dawah-target')}-${meetDawahTarget} | ${t('worker')}-${meetWorker} | ${t('ruqon')}-${meetMember}` },
      { label: `${t('org-time')}`, content: `${timeSpend} ` },
      { label: `${t('misscellaneous')}`, content: `${t('criticism')}-${selfCriticism} | ${t('social')}-${socialWork} | ${t('debt')}- ${debt} | ` },
      { label: `${t('family')}`, content: `${t('family')}-${familyMeeting} | ${t('visit')}-${travel} |  ${t('report')} - ${report} | ` },
    ];

    return (
      <Document>
        <Page size="A4" style={styles.page}>
          {/* Title */}
          <Text style={styles.title}>{t('summary')} - {currentMonth} {currentYear}</Text>

          {/* Date */}
          <Text style={{ marginBottom: 10 }}>Date: {new Date().toLocaleDateString('bn-BD')}</Text>

          {/* Table */}
          <View style={styles.table}>
            <View style={[styles.row, { backgroundColor: '#f0f0f0' }]}>
              <Text style={[styles.cell, styles.bold]}>Label</Text>
              <Text style={[styles.cell, styles.bold]}>Content</Text>
            </View>
            {reportContent.map((item, index) => (
              <View key={index} style={styles.row}>
                <Text style={styles.cell}>{item.label}</Text>
                <Text style={[styles.cell, styles.bold]}>{item.content}</Text>
              </View>
            ))}
          </View>

          {/* Comments Section */}
          <View style={styles.section}>
            <Text style={[styles.bold, { marginBottom: 5 }]}>{`${t('comment')}`}:</Text>
            {comment ? comment.split('\n').map((c, i) => (
              <Text key={i}>{c}</Text>
            )) : <Text>No comments</Text>}
          </View>
        </Page>
      </Document>
    );
  };


  const handleShare = async () => {
    try {
      if (navigator.share) {
        const pdfBlob = await fetch(pdfUrl);
        const pdfFile = await pdfBlob.blob();

        // Create a File object from the Blob
        const file = new File([pdfFile], 'Report_summery.pdf', { type: 'application/pdf' });

        // Use the Web Share API
        await navigator.share({
          title: 'Monthly Report Summery',
          text: 'Here is my monthly Report Summery PDF!',
          files: [file], // Files array with the generated PDF
        });
      } else {
        alert('Sharing is not supported on this browser or device.');
      }
    } catch (error) {
      console.error('Error sharing PDF', error);
    }
  };


  useEffect(() => {
    if (!user) {
      Swal.fire({
        title: 'Warning',
        text: "You are not logged in. Please log in to access your profile.",
        icon: 'warning',
        confirmButtonText: 'Ok.'
      }).then((result) => {
        if (result['isConfirmed']) {
          // Put your function here
          navigate('/');
        }
      })
      // alert('You are not logged in. Please log in to access your profile.');
      // navigate('/');
    }
  }, [user]);

  const isFutureMonth = (date) => {
    const now = new Date();
    const selectedDate = new Date(date);
    return (
      selectedDate.getFullYear() > now.getFullYear() ||
      (selectedDate.getFullYear() === now.getFullYear() && selectedDate.getMonth() > now.getMonth())
    );
  };

  useEffect(() => {
    var y = selectedDate.getFullYear();
    var m = ('0' + (selectedDate.getMonth() + 1)).slice(-2);
    //console.log(m);
    setMonth(m);
    setYear(y);
  }, [selectedDate]);

  const prevMonth = () => {
    const prev = moment(selectedDate).subtract(1, 'months');
    if (prev < minimumDate) {
      Swal.fire({
        title: "Warning!",
        text: "You cant go beyond that!.",
        icon: "warning"
      });
    }
    else {
      setSelectedDate(new Date(prev));
    }
  }
  const previousMonth = moment(selectedDate).subtract(1, 'months');

  const nextMonth = () => {
    const nxt = moment(selectedDate).add(1, 'months');
    if (nxt <= moment()) {
      setSelectedDate(new Date(nxt));
    }
    else {
      Swal.fire({
        title: "Warning!",
        text: "You can't see future summary.",
        icon: "warning"
      });
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    API.getSummarys(token, user?.user_id)
      .then(resp => setAllSummary(resp))
      .catch(error => console.log(error))
  }, [gotSummary]);

  const getSummary = () => {
    //console.log(allsummarys);
    if (allSummary.length > 0) {
      const summary = allSummary.filter((item) => item.month == month && item.year == year && item.user == user.user_id);
      const summaryPrev = allSummary.filter((item) => item.month == previousMonth && item.year == year && item.user == user.user_id);
      //setP(summary.length);
      if (summary.length != 0) {
        //console.log(summary);
        setSummaryId(summary[0].id);
        //setQuranStudy(summary.map((item) => item.quranStudy));
        setQuranStudy(summary[0].quranStudy);
        setQuranStudyDays(summary[0].quranStudyDays);
        setQuranDars(summary[0].quranDars);
        setQuranMemorize(summary[0].quranMemorize);

        setHadithStudy(summary[0].hadithStudy);
        setHadithStudyDays(summary[0].hadithStudyDays);
        setHadithDars(summary[0].hadithDars);
        setHadithMemorize(summary[0].hadithMemorize);

        setBookStudyIslamic(summary[0].bookStudyIslamic);
        setBookStudyOther(summary[0].bookStudyOther);
        setSalatJamat(summary[0].salatJamat);
        setSalatKaja(summary[0].salatKaja);
        setMemberContact(summary[0].memberContact);
        setWorkerContact(summary[0].workerContact);

        setDistributionIslamic(summary[0].distributionIslamic);
        setDistributionOther(summary[0].distributionOther);

        setMeetDawahTarget(summary[0].meetDawahTarget);
        setMeetMember(summary[0].meetMember);
        setMeetWorker(summary[0].meetWorker);
        setSocialWork(summary[0].socialWork);
        setFamilyMeeting(summary[0].familyMeeting);
        setTimeSpend(summary[0].timeSpend);
        setSelfCriticism(summary[0].selfCriticism);
        setTravel(summary[0].travel);
        setDebt(summary[0].debt);
        setReport(summary[0].report);
        setComment(summary[0].comment);
      }
      else {
        setSummaryId(0);
        setQuranStudy(null);
        setQuranStudyDays(null);
        setQuranMemorize(null);
        setQuranDars(null);

        setHadithStudy(null);
        setHadithStudyDays(null);
        setHadithMemorize(null);
        setHadithDars(null);

        setBookStudyIslamic(null);
        setBookStudyOther(null);
        setSalatJamat(null);
        setSalatKaja(null);
        setMemberContact(null);
        setWorkerContact(null);

        setDistributionIslamic(null);
        setDistributionOther(null);

        setMeetDawahTarget(null);
        setMeetMember(null);
        setMeetWorker(null);
        setSocialWork(null);
        setFamilyMeeting(null);
        setTimeSpend(null);
        setSelfCriticism(null);
        setTravel(null);
        setDebt(null);
        setReport(null);
        setComment('');
      }

      if (summaryPrev.length != 0) {
        setQuranStudyDaysPrev(summaryPrev[0].quranStudyDays);
        setHadithStudyDaysPrev(summaryPrev[0].hadithStudyDays);
        setBookStudyIslamicPrev(summaryPrev[0].bookStudyIslamic);
        setSalatJamatPrev(summaryPrev[0].bookStudyIslamic);
        setMemberContactPrev(summaryPrev[0].memberContact);
        setWorkerContactPrev(summaryPrev[0].workerContact);
        setMeetWorkerPrev(summaryPrev[0].meetWorker);
        setTimeSpendPrev(summaryPrev[0].timeSpend);
        setDebtPrev(summaryPrev[0].debt);
        setReportPrev(summaryPrev[0].reportPrev);
        setTravelPrev(summaryPrev[0].travel);
        setSocialWorkPrev(summaryPrev[0].socialWork);
        setFamilyMeetingPrev(summaryPrev[0].familyMeeting);
        setSelfCriticismPrev(summaryPrev[0].selfCriticism);
      }
    }
  }

  useEffect(() => {
    if (allSummary.length > 0) {
      setLoading(false);
    }
  }, [allSummary]);

  useEffect(() => {
    getSummary();
    //setDays(new Date(year, month, 0).getDate());
  }, [allSummary, month, year]);

  const handleSubmit = async () => {
    if (!user) {
      Swal.fire({
        title: 'Warning',
        text: "You need to log in first.",
        icon: 'warning',
        confirmButtonText: 'Ok.'
      }).then((result) => {
        if (result['isConfirmed']) {
          // Put your function here
          navigate('/');
        }
      })
      return;
    }
    else {
      setLoading(true);
      const data = {
        user: user.user_id,
        year,
        month,
        comment,
      };
      console.log("Summary Id" + summaryId);
      if (summaryId == 0) {
        try {
          //const apiUrl = 'http://127.0.0.1:8000/api/plan/';
          API.createSummary(data, token)
            .then(resp => {
              setLoading(false);
              // console.log(resp);
              if (resp.user == user.user_id) {
                Swal.fire({
                  title: 'Success',
                  text: "Comment successfully Added.",
                  icon: 'success',
                  confirmButtonText: 'Ok.'
                })
              }
              else {
                Swal.fire({
                  title: 'Warning',
                  text: "Error resp , Comment Not Added.",
                  icon: 'warning',
                  confirmButtonText: 'Ok.'
                })
              }
            })
            .catch(error => {
              setLoading(false);
              //console.log(error);
              alert(`Submission Error. An error occurred: ${error.message}. Please try again later.`);
            });
        } catch (error) {
          setLoading(false);
          console.error('API Error:', error);
          Swal.fire({
            title: 'Warning',
            text: "Submission Error. Please try again later.",
            icon: 'warning',
            confirmButtonText: 'Ok.'
          })
        }
      }
      else {
        try {
          API.updateSummary(summaryId, data, token)
            .then(resp => {
              setLoading(false);
              //console.log(resp);
              //console.log(data);
              //console.log(reportid);
              // console.log(" the response in updation : ", resp)
              if (resp.user == user.user_id) {
                Swal.fire({
                  title: 'Success',
                  text: "Comment successfully Updated!",
                  icon: 'success',
                  confirmButtonText: 'Ok.'
                })
                setGotSummary(!gotSummary);
              }
              else {
                Swal.fire({
                  title: 'Warning',
                  text: "Oops! Comment Not Updated!",
                  icon: 'warning',
                  confirmButtonText: 'Ok.'
                })
              }
            })
        } catch (error) {
          setLoading(false);
          // console.log("update time error => ", error);
          Swal.fire({
            title: 'Warning',
            text: "Error! Comment Not Updated!",
            icon: 'warning',
            confirmButtonText: 'Ok.'
          })
        }

      }
    }



  };


  // Utility function to handle both conversions
  // Utility function to handle both conversions, with null/undefined check
  const convertNumbers = (value, toBengali = true) => {
    if (!value) return '';  // Ensure value is not null or undefined

    const englishToBengaliDigits = {
      '0': '০', '1': '১', '2': '২', '3': '৩', '4': '৪', '5': '৫',
      '6': '৬', '7': '৭', '8': '৮', '9': '৯',
    };

    const bengaliToEnglishDigits = {
      '০': '0', '১': '1', '২': '2', '৩': '3', '৪': '4',
      '৫': '5', '৬': '6', '৭': '7', '৮': '8', '৯': '9',
    };

    const digitsMap = toBengali ? englishToBengaliDigits : bengaliToEnglishDigits;

    // Ensure that `value` is a string before calling `split`
    return value.toString().split('').map(digit => digitsMap[digit] || digit).join('');
  };


  const isBengali = i18n.language === 'bn'; // Check if the current language is Bengali


  return (
    <div>
      <Navbar />
      <div className='report-summary-middle-part'>
        <div className='report-summary-middle-part-1st-row'>
          <div className='report-summary-middle-part-1st-row-title'>{t('summary')}</div>

          <div className='report-summary-middle-part-1st-row-lowerpart'>
            {/* <Dropdown options={Month}  placeholder="Month" />
              <Dropdown options={Year}  placeholder="Year" /> */}
            <button onClick={prevMonth}>&lt;</button>
            <DatePicker
              className='report-summary-middle-part-1st-row-datepicker'
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="MMM''yy"  // Format as "Jan'24"
              showMonthYearPicker
              filterDate={(date) => date <= new Date()}  // Disable future months
              locale={i18n.language === 'bn' ? 'bn' : 'en'}  // Set locale dynamically
              placeholderText={i18n.language === 'bn' ? 'তারিখ নির্বাচন করুন' : 'Select date'}
            />
            <button onClick={nextMonth}>&gt;</button>
          </div>
          <div className='report-summary-middle-part-1st-row-total-days'>
            <div className='report-summary-middle-part-1st-row-total-days-title'>{t('total-days')}</div>
            <div21 className='report-summary-middle-part-1st-row-lowerpart-total-days'>{isBengali ? convertNumbers(report, true) : report}</div21>
          </div>
        </div>

        <Container className='report-summary-data-part'>
          <Row className='report-summary-data-part-1st-row'>
            <Col xs xl md lg={2} sm className='report-summary-data-part-topic'>
              {t('quran')}
            </Col>
            <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>
              {t('days')}
            </Col>
            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
              {isBengali ? convertNumbers(quranStudyDays, true) : quranStudyDays}
            </Col>

            <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>
              {t('reading')}
            </Col>
            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
              {isBengali ? convertNumbers(quranStudy, true) : quranStudy}
            </Col>

            <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>
              {t('memorization')}
            </Col>
            <Col xs={4} xl md lg={0.5} sm className='report-summary-data-part-Number'>
              {isBengali ? convertNumbers(quranMemorize, true) : quranMemorize}
            </Col>

            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-days'>
              {t('dars')}
            </Col>
            <Col xs={4} xl md lg={0.5} sm className='report-summary-data-part-Number' >
              {isBengali ? convertNumbers(quranDars, true) : quranDars}
            </Col>
            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-days'>
              {t('quality')}
            </Col>
            <Col xs={4} xl md lg={0.5} sm className={quranStudyDays > quranStudyDaysPrev ? 'report-summary-upper-arrow' : 'report-summary-lower-arrow'}>
              {quranStudyDays > quranStudyDaysPrev ? <NorthIcon /> : <SouthIcon />}
            </Col>

          </Row>

          <Row className='report-summary-data-part-1st-row'>
            <Col xs xl md lg={2} sm className='report-summary-data-part-topic'>
              {t('hadith')}
            </Col>
            <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>
              {t('days')}
            </Col>
            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
              {isBengali ? convertNumbers(hadithStudyDays, true) : hadithStudyDays}
            </Col>

            <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>
              {t('reading')}
            </Col>

            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
              {isBengali ? convertNumbers(hadithStudy, true) : hadithStudy}
            </Col>

            <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>
              {t('memorization')}
            </Col>
            <Col xs={4} xl md lg={0.5} sm className='report-summary-data-part-Number'>
              {isBengali ? convertNumbers(hadithMemorize, true) : hadithMemorize}
            </Col>

            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-days'>
              {t('dars')}
            </Col>
            <Col xs={4} xl md lg={0.5} sm className='report-summary-data-part-Number'>
              {isBengali ? convertNumbers(hadithDars, true) : hadithDars}
            </Col>

            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-days'>
              {t('quality')}
            </Col>
            <Col xs={4} xl md lg={0.5} sm className={hadithStudyDays > hadithStudyDaysPrev ? 'report-summary-upper-arrow' : 'report-summary-lower-arrow'}>
              {hadithStudyDays > hadithStudyDaysPrev ? <NorthIcon /> : <SouthIcon />}
            </Col>

          </Row>

          <Row className='report-summary-data-part-1st-row'>
            <Col xs xl md lg={2} sm className='report-summary-data-part-literature'>
              {t('literature')}
            </Col>
            <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>
              {t('islamic')}
            </Col>
            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
              {isBengali ? convertNumbers(bookStudyIslamic, true) : bookStudyIslamic}
            </Col>

            <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>
              {t('other')}
            </Col>
            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
              {isBengali ? convertNumbers(bookStudyOther, true) : bookStudyOther}
            </Col>
            <Col xs={4} xl md lg={1} sm></Col>
            <Col xs={2} xl md lg={1} sm></Col>
            <Col xs={3} xl md lg={1} sm></Col>
            <Col xs={3} xl md lg={0.5} sm></Col>

            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-days'>
              {t('quality')}
            </Col>
            <Col xs={4} xl md lg={0.5} sm className={bookStudyIslamic > bookStudyIslamicPrev ? 'report-summary-upper-arrow' : 'report-summary-lower-arrow'}>
              {bookStudyIslamic > bookStudyIslamicPrev ? <NorthIcon /> : <SouthIcon />}

            </Col>

          </Row>

          <Row className='report-summary-data-part-1st-row'>

            <Col xs xl md lg={2} sm className='report-summary-data-part-literature'>
              {t('namaz')}
            </Col>
            <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>
              {t('jamat')}
            </Col>
            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
              {isBengali ? convertNumbers(salatJamat, true) : salatJamat}
            </Col>

            <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>
              {t('kadha')}
            </Col>
            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
              {isBengali ? convertNumbers(salatKaja, true) : salatKaja}
            </Col>
            <Col xs={4} xl md lg={1} sm></Col>
            <Col xs={4} xl md lg={1} sm></Col>
            <Col xs={2} xl md lg={1} sm></Col>
            <Col xs={2} xl md lg={0.5} sm></Col>

            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-days'>
              {t('quality')}
            </Col>
            <Col xs={4} xl md lg={0.5} sm className={salatJamat > salatJamatPrev ? 'report-summary-upper-arrow' : 'report-summary-lower-arrow'}>
              {salatJamat > salatJamatPrev ? <NorthIcon /> : <SouthIcon />}

            </Col>

          </Row>

          <Row className='report-summary-data-part-1st-row'>

            <Col xs xl md lg={2} sm className='report-summary-data-part-literature'>
              {t('contact')}
            </Col>
            <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>
              {t('worker')}
            </Col>
            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
              {isBengali ? convertNumbers(workerContact, true) : workerContact}
            </Col>

            <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>
              {t('ruqon')}
            </Col>
            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
              {isBengali ? convertNumbers(memberContact, true) : memberContact}
            </Col>
            <Col xs={4} xl md lg={1} sm></Col>
            <Col xs={4} xl md lg={1} sm></Col>
            <Col xs={2} xl md lg={1} sm></Col>
            <Col xs={2} xl md lg={0.5} sm></Col>

            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-days'>
              {t('quality')}
            </Col>
            <Col xs={4} xl md lg={0.5} sm className={memberContact > memberContactPrev ? 'report-summary-upper-arrow' : 'report-summary-lower-arrow'}>
              {memberContact > memberContactPrev ? <NorthIcon /> : <SouthIcon />}

            </Col>


          </Row>

          <Row className='report-summary-data-part-1st-row'>

            <Col xs xl md lg={2} sm className='report-summary-data-part-literature'>
              {t('distribution')}
            </Col>
            <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>
              {t('islamic')}
            </Col>
            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
              {isBengali ? convertNumbers(distributionIslamic, true) : distributionIslamic}
            </Col>

            <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>
              {t('other')}
            </Col>
            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
              {isBengali ? convertNumbers(distributionOther, true) : distributionOther}
            </Col>
            <Col xs={4} xl md lg={1} sm></Col>
            <Col xs={4} xl md lg={1} sm></Col>
            <Col xs={2} xl md lg={1} sm></Col>
            <Col xs={2} xl md lg={0.5} sm></Col>

            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-days'>
              {t('quality')}
            </Col>
            <Col xs={4} xl md lg={0.5} sm className={distributionIslamic > distributionIslamicPrev ? 'report-summary-upper-arrow' : 'report-summary-lower-arrow'}>
              {distributionIslamic > distributionIslamicPrev ? <NorthIcon /> : <SouthIcon />}

            </Col>


          </Row>

          <Row className='report-summary-data-part-1st-row'>

            <Col xs xl md lg={2} sm className='report-summary-data-part-literature'>
              {t('meet')}
            </Col>
            <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>
              {t('dawah-target')}
            </Col>
            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
              {isBengali ? convertNumbers(meetDawahTarget, true) : meetDawahTarget}
            </Col>

            <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>
              {t('worker')}
            </Col>
            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
              {isBengali ? convertNumbers(meetWorker, true) : meetWorker}
            </Col>
            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-days'>
              {t('ruqon')}
            </Col>
            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
              {isBengali ? convertNumbers(meetMember, true) : meetMember}
            </Col>
            <Col xs={4} xl md lg={1} sm></Col>
            <Col xs={4} xl md lg={0.5} sm></Col>

            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-days'>
              {t('quality')}
            </Col>
            <Col xs={4} xl md lg={0.5} sm className={meetDawahTarget > meetDawahTargetPrev ? 'report-summary-upper-arrow' : 'report-summary-lower-arrow'}>
              {meetDawahTarget > meetDawahTargetPrev ? <NorthIcon /> : <SouthIcon />}

            </Col>

          </Row>

          <Row className='report-summary-data-part-1st-row'>

            <Col xs xl md lg={2} sm className='report-summary-data-part-literature'>
              {t('org-time')}
            </Col>
            <Col xs={4} xl md lg={1.50} sm className='report-summary-data-part-days'>
            </Col>
            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-Number'>
              {isBengali ? convertNumbers(timeSpend, true) : timeSpend}
            </Col>

            <Col xs={4} xl md lg={1.5} sm className='report-summary-data-part-days'>

            </Col>
            <Col xs={4} xl md lg={1} sm >

            </Col>
            <Col xs={4} xl md lg={1} sm >

            </Col>
            <Col xs={4} xl md lg={1} sm >

            </Col>
            <Col xs={2} xl md lg={1} sm></Col>
            <Col xs={2} xl md lg={0.5} sm></Col>

            <Col xs={4} xl md lg={1} sm className='report-summary-data-part-days'>
              {t('quality')}
            </Col>
            <Col xs={4} xl md lg={0.5} sm className={timeSpend > timeSpendPrev ? 'report-summary-upper-arrow' : 'report-summary-lower-arrow'}>
              {timeSpend > timeSpendPrev ? <NorthIcon /> : <SouthIcon />}

            </Col>

          </Row>

          <Row className='report-summary-data-part-miss-row'>
            <Col xs xl md lg={2} sm className='report-summary-data-part-miss'>
              {t('misscellaneous')}
            </Col>
            <Col xs xl md lg={4.72} sm className='report-summary-data-part-miss-1st-column'>
              <Row className='report-summary-data-part-miss-1st-column-1st-row'>

                <Col xs={3} xl md lg={3.8} sm >{t('criticism')}</Col>
                <Col xs={3} xl md lg={2.7} sm className='report-summary-data-part-Number'>{isBengali ? convertNumbers(selfCriticism, true) : selfCriticism}</Col>
                {/* <Col xs={4} xl md lg={2.7} sm  className='report-summary-data-part-days'>Quality</Col> */}
                <Col xs={3} xl md lg={2.7} sm className={selfCriticism > selfCriticismPrev ? 'report-summary-upper-arrow' : 'report-summary-lower-arrow'}>
                  {selfCriticism > selfCriticismPrev ? <NorthIcon /> : <SouthIcon />}

                </Col>

              </Row>
              <Row className='report-summary-data-part-miss-1st-column-2nd-row'>
                <Col xs={3} xl md lg={3.8} sm>{t('social')}</Col>
                <Col xs={3} xl md lg={2.7} sm className='report-summary-data-part-Number'>{isBengali ? convertNumbers(socialWork, true) : socialWork}</Col>
                <Col xs={3} xl md lg={2.7} sm className={socialWork > socialWorkPrev ? 'report-summary-upper-arrow' : 'report-summary-lower-arrow'}>
                  {socialWork > socialWorkPrev ? <NorthIcon /> : <SouthIcon />}

                </Col>

              </Row>
              <Row className='report-summary-data-part-miss-1st-column-3rd-row'>
                <Col xs={3} xl md lg={3.8} sm >{t('debt')}</Col>
                <Col xs={3} xl md lg={2.7} sm className='report-summary-data-part-Number'>{isBengali ? convertNumbers(debt, true) : debt}</Col>
                <Col xs={3} xl md lg={2.7} sm className={debt > debtPrev ? 'report-summary-upper-arrow' : 'report-summary-lower-arrow'}>
                  {debt > debtPrev ? <NorthIcon /> : <SouthIcon />}

                </Col>

              </Row>
            </Col>


            <Col xs xl md lg={4.72} sm className='report-summary-data-part-miss-2nd-column'>
              <Row className='report-summary-data-part-miss-2nd-column-1st-row'>
                <Col xs={3} xl md lg={4} sm >{t('family')}</Col>
                <Col xs={3} xl md lg={2.7} sm className='report-summary-data-part-Number'>{isBengali ? convertNumbers(familyMeeting, true) : familyMeeting}</Col>
                <Col xs={3} xl md lg={2.7} sm className={familyMeeting > familyMeetingPrev ? 'report-summary-upper-arrow' : 'report-summary-lower-arrow'}>
                  {familyMeeting > familyMeetingPrev ? <NorthIcon /> : <SouthIcon />}

                </Col>

              </Row>
              <Row className='report-summary-data-part-miss-2nd-column-2nd-row'>
                <Col xs={3} xl md lg={4} sm >{t('visit')}</Col>
                <Col xs={3} xl md lg={2.7} sm className='report-summary-data-part-Number'>{isBengali ? convertNumbers(travel, true) : travel}</Col>
                <Col xs={3} xl md lg={2.7} sm className={travel > travelPrev ? 'report-summary-upper-arrow' : 'report-summary-lower-arrow'}>
                  {travel > travelPrev ? <NorthIcon /> : <SouthIcon />}

                </Col>

              </Row>
              <Row className='report-summary-data-part-miss-2nd-column-3rd-row'>
                <Col xs={3} xl md lg={4} sm >{t('report')}</Col>
                <Col xs={3} xl md lg={2.7} sm className='report-summary-data-part-Number'>{isBengali ? convertNumbers(report, true) : report}</Col>
                <Col xs={3} xl md lg={2.7} sm className={report > reportPrev ? 'report-summary-upper-arrow' : 'report-summary-lower-arrow'}>
                  {report > reportPrev ? <NorthIcon /> : <SouthIcon />}

                </Col>

              </Row>
            </Col>

            {/* //<Col></Col> */}

          </Row>

          <div className='report-summary-data-part-comment'>
            <div1>{t('comment')}</div1>
            <textarea
              type='text'
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>

          <div className='report-summary-change-saved' onClick={handleSubmit}>{loading ? t('saving') : summaryId == 0 ? t('save') : t('update')}</div>

          <div className='report-summary-middle-part-bottom-row'>
            {/* <div className='report-summary-middle-part-add-new'> */}
            {/* <DownloadForOfflineIcon /> */}

            <PDFDownloadLink
              document={<MyPDFDocument reportData={allSummary} selectedDate={selectedDate} comment={comment} />}
              fileName="report_summary.pdf"
              style={{ textDecoration: 'none' }} // Remove underline
            >
              {({ loading, url }) => {
                setPdfUrl(url);
                return (
                  <div className="mx-auto" style={{
                    backgroundColor: '#65FFBE',
                    // width: '100%',
                    textAlign: 'center',
                    padding: '5px',
                    marginTop: '10px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    // justifyContent: 'center', // Center content
                    // Optional: Rounded corners for a better look
                  }}>
                    <DownloadForOfflineIcon />
                    {t('download')}
                  </div>
                )
              }}
            </PDFDownloadLink>

            {/* </div> */}
            {/* <div className='report-summary-middle-part-add-new' onClick={generatePdf}>
                  <DownloadForOfflineIcon />
                  {t('download')} 
                </div> */}
            <div className='report-summary-middle-part-add-new' onClick={handleShare}>
              <ShareIcon />
              {t('share')}
            </div>
            {/* <div className='report-summary-middle-part-add-new' onClick={sharePdf}>
              <ShareIcon />
              {t('share')}
            </div> */}
          </div>

        </Container>



      </div>
      <MyFooter />
    </div>
  )
}

export default ReportSummary