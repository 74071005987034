import './ComponentsCSS/footer.css';
import footer_qr_code from '../assets/footer_qr_code.png';
import footer_google_play from '../assets/footer_google_play.png';
import { Link } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import config from '../config';
import axios from 'axios';
import AuthContext from '../context/AuthContext';

function MyFooter() {
    const [address, setAddress] = useState(null);
    const [contactEmail, setContactEmail] = useState(null);
    const [copyrightText, setCopyrightText] = useState(null);
    const [facebookLink, setFacebookLink] = useState(null);
    const [youtubeLink, setYoutubeLink] = useState(null);
    const [instaLink, setInstaLink] = useState(null);
    const [xLink, setXLink] = useState(null);

    const { authTokens } = useContext(AuthContext)
    const token = authTokens?.access;

    useEffect(() => {
        const fetchFooterData = async () => {
          try {
            const response = await axios.get(`${config.apiUrl}footer/`, {
              headers: {
                "Content-Type": "application/json",
              },
            });
    
            const result = response.data;
            // console.log("PHooter Text is here",result);
            
            if (Array.isArray(result) && result.length > 0) {
              const footerData = result[0];
    
              setAddress(footerData.address || null);
              setContactEmail(footerData.contactEmail || null);
              setCopyrightText(footerData.copyrightText || null);
              setFacebookLink(footerData.facebookLink || null);
              setYoutubeLink(footerData.youtubeLink || null);
              setInstaLink(footerData.instaLink || null);
              setXLink(footerData.xLink || null);
            }
          } catch (error) {
            console.error("Error fetching footer data:", error);
          }
        };
    
        fetchFooterData();
      }, []);

    return (
        <div className="footer-container">


            <footer className="bg-green-400 dark:bg-gray-900">
                <div className="mx-auto w-full max-w-screen-xl">
                    <div className="grid grid-cols-2 gap-5 px-2 py-3 lg:py-8 md:grid-cols-4 justify-between items-start">
                            {/* <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Company</h2> */}
                            <div className="text-white-500 dark:text-gray-400 font-medium gap-4 ">
                                     {address}
 
                                 <p className='mt-4'> <strong>
                                     {contactEmail}
                                     </strong> 
                                     </p>  
                                 </div>
                         
                             
                            <ul className="text-white-500 dark:text-gray-400 font-medium">
                               
                                <li className="mb-4">
                                    <Link to="/aboutus" className=" hover:underline">
                                        About Us
                                    </Link>
                                </li>
                                
                                <li className="mb-4">
                                    <Link to="/termsandconditions" className="hover:underline">Terms & Conditions</Link>
                                </li>
                                <li className="mb-4">
                                    <Link to="/privacypolicy" className="hover:underline">Privacy Policy</Link>
                                </li>
                                 
                            </ul>
                             
                            <ul className="text-white-500 dark:text-gray-400 font-medium">
                                <li className="mb-4">
                                    <Link to="/help" className="hover:underline">Help</Link>
                                </li>
                                
                                <li className="mb-4">
                                    <Link to="/contactus" className="hover:underline">Contact Us
                                    </Link>
                                </li>
                                <li className="mb-4">
                                    <Link to="https://www.bjilibrary.com/" target='#' className="hover:underline">Online Library</Link>
                                </li>
                            </ul>
                            {/* <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Download</h2> */}
                            <ul className="text-gray-500 dark:text-gray-400 font-medium flex flex-col lg:flex-row lg:items-center lg:gap-4">
                                <li className="mb-4">
                                    {/* <a href="#" className="hover:underline">iOS</a> */}
                                    <img src={footer_qr_code} alt='footer' />
                                </li>
                                <li className="mb-4" style={{ cursor: 'pointer' }}>
                                    <Link to="https://play.google.com/store/games?device=phone" className="hover:underline"><img src={footer_google_play} alt='footer_google' /></Link>

                                </li>
                            </ul>

                    </div>
                    

                </div>
            </footer>
            <div className="px-4 py-6 bg-green-800 dark:bg-gray-700 md:flex md:items-center md:justify-between">
            <span className="text-sm text-white dark:text-gray-300 sm:text-center"> 
                     {copyrightText}
                 </span>
                 <div className="flex mt-4 sm:justify-center md:mt-0 space-x-5 rtl:space-x-reverse">
                     <Link to={`${facebookLink}`} target='#' className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                         <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                             <path fillRule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clipRule="evenodd" />
                         </svg>
                         <span className="sr-only">Facebook page</span>
                     </Link>
                            
                     <Link to={`${xLink}`} target='#' className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                         <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 17">
                             <path fillRule="evenodd" d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z" clipRule="evenodd" />
                         </svg>
                         <span className="sr-only">Twitter page</span>
                     </Link>
                     <Link  to={`${instaLink}`} target='#' className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-instagram" viewBox="0 0 20 20">
                                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                                </svg>
                                <span className="sr-only">GitHub account</span>
                            </Link>
                            
                            <Link to={`${youtubeLink}`} target='#' className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                         <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                             <path d="M23.49 6.24c-.28-1.05-1.06-1.87-2.11-2.14-1.85-.31-9.33-.31-9.33-.31s-7.48 0-9.33.31c-1.05.27-1.83 1.09-2.11 2.14-.28 1.03-.28 3.2-.28 3.2s0 2.17.28 3.2c.28 1.05 1.06 1.87 2.11 2.14 1.85.31 9.33.31 9.33.31s7.48 0 9.33-.31c1.05-.27 1.83-1.09 2.11-2.14.28-1.03.28-3.2.28-3.2s0-2.17-.28-3.2zM9.54 15.37v-6.74l6.43 3.37-6.43 3.37z" />
                         </svg>
                         <span className="sr-only">YouTube Account</span>
                     </Link>

                        </div>
                    </div>
        </div>

    );
}

export default MyFooter;